import React, { useState, useEffect } from "react";
import "./../styles/Navbar.css";
import { useNavigate } from "react-router-dom";
import { useStompClient } from "react-stomp-hooks";
import { useDispatch } from "react-redux";
import { removeUser } from "../redux/action";
import {
  TbLayoutSidebarLeftExpandFilled,
  TbLayoutSidebarLeftCollapseFilled,
} from "react-icons/tb";

function Navbar({ menu, setMenu }) {
  const [user, setUser] = useState("");
  const stompClient = useStompClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const storedUser = localStorage.getItem("chatapp-user");
    if (storedUser) {
      setUser(storedUser);
    }

    // eslint-disable-next-line
  }, [localStorage.getItem("chatapp-user")]);

  const logout = () => {
    if (stompClient) {
      var message = `${user} left this chat...`;
      var mess = { name: user, message: message, type: "LEFT" };
      stompClient.publish({
        destination: "/app/chat.send",
        body: JSON.stringify(mess),
      });
    }

    dispatch(removeUser(user));
    localStorage.removeItem("chatapp-user");
    setUser("");
    navigate("/login");
  };

  const getIcon = () => {
    if (!menu) {
      return <TbLayoutSidebarLeftExpandFilled />;
    } else {
      return <TbLayoutSidebarLeftCollapseFilled />;
    }
  };

  return (
    <div className="navbar-container">
      <div className="navbar-logo">
        <span onClick={() => setMenu(!menu)}>{getIcon()}</span>
        <h2>Incognito Chat</h2>
      </div>
      <div className="navbar-right">
        <div className="navbar-user">{user && user}</div>
        {user && <button onClick={logout}>Logout</button>}
      </div>
    </div>
  );
}

export default Navbar;
