export const ADD_USER = 'ADD_USER'
export const REMOVE_USER = 'REMOVE_USER'

export const addUser = (payload) => ({
    type : ADD_USER,
    payload : payload
})

export const removeUser = (payload) => ({
    type : REMOVE_USER,
    payload : payload
})