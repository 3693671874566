import React, { useEffect, useState } from "react";
import "./../styles/Login.css";
import { useNavigate } from "react-router";
import { useStompClient } from "react-stomp-hooks";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../redux/action";
function Login() {
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  const stompClient = useStompClient();
  const dispatch = useDispatch();

  const users = useSelector((data) => data.users.users);

  useEffect(() => {
    const u = localStorage.getItem("chatapp-user");
    if (users.includes(u)) {
      setUser(u);
    } else {
      localStorage.removeItem("chatapp-user");
      setUser("");
    }
    // eslint-disable-next-line
  }, []);

  const handleLogin = () => {
    if (stompClient) {
      if (users.includes(user)) {
        alert("User Already Exists, Please login with some other name");
        return;
      }
      localStorage.setItem("chatapp-user", user);
      navigate("/chat");
      joinChat();
    } else {
      alert("Failed to connect to the server. Reloading in 5 seconds...");
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  const joinChat = () => {
    var mess = { name: user, message: "", type: "JOIN" };
    stompClient.publish({
      destination: "/app/chat.register",
      body: JSON.stringify(mess),
    });
    dispatch(addUser(user));
  };

  return (
    <div className="login-container">
      <h1 className="login-message">Welcome! to the Incognito chat</h1>
      <p className="login-message">Please enter your name to start chatting.</p>
      <input
        type="text"
        placeholder="Enter Your Name"
        value={user}
        onChange={(e) => setUser(e.target.value)}
        className="login-input"
      />
      <button
        className="login-btn"
        onClick={() => {
          user && handleLogin();
        }}
      >
        Start
      </button>
    </div>
  );
}

export default Login;
