import React, {  useRef, useState } from "react";
import { useStompClient, useSubscription } from "react-stomp-hooks";
import { IoSend } from "react-icons/io5";
import "./../styles/Chat.css";
import { useDispatch, useSelector } from "react-redux";
import { addUser, removeUser } from "../redux/action";
import { formatMessageTime } from "../utils/api";

function ChatComponent() {
  const stompClient = useStompClient();

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const user = localStorage.getItem("chatapp-user");

  const dispatch = useDispatch();
  const users = useSelector((data) => data.users);

  const messagesEndRef = useRef(null);

  // // Scroll to the bottom of the chat window when messages change
  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  // // Function to scroll to the bottom of the chat window
  // const scrollToBottom = () => {
  //   const lastChildElement = messagesEndRef.current?.lastElementChild;
  //   lastChildElement?.scrollIntoView({ behavior: "smooth" });
  // };

  useSubscription("/topic/public", (message) => {
    const parsedMessage = JSON.parse(message.body);

    if (parsedMessage.type === "JOIN") {
      parsedMessage.message = `${parsedMessage?.name} has joined this chat room`;
      dispatch(addUser(parsedMessage.name));
      setMessages((prevMsg) => [...prevMsg, parsedMessage]);
    }
    if (parsedMessage.type === "LEFT") {
      parsedMessage.message = `${parsedMessage?.name} has left this chat room`;
      dispatch(removeUser(parsedMessage.name));

      setMessages((prevMsg) => [...prevMsg, parsedMessage]);
    }
    if (parsedMessage.type === "CHAT") {
      setMessages((prevMsg) => [...prevMsg, parsedMessage]);
    }
  });

  const publishMessage = () => {
    if (stompClient) {
      var mess = { name: user, message: message, type: "CHAT" };
      stompClient.publish({
        destination: "/app/chat.send",
        body: JSON.stringify(mess),
      });
      setMessage("");
      console.log(users);
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-messages-container" ref={messagesEndRef}>
        {messages.map((msg, index) =>
          msg.type === "JOIN" || msg.type === "LEFT" ? (
            <div className="chat-message-container-left" key={index}>
              <div className="chat-message-warning">
                {" "}
                {msg.name} {msg.type?.toLowerCase()} this chat...
              </div>
            </div>
          ) : (
            <div
              key={`message-${index}`}
              className={`${
                msg.name === user
                  ? "chat-message-container-right"
                  : "chat-message-container-left"
              }`}
              title={msg?.name}
            >
              <div className="chat-sender-icon">
                {" "}
                {msg?.name?.substring(0, 1)}
              </div>
              <div className="chat-message">
                <span className="chat-sender-details">
                  <span className="chat-sender-name">~ {msg?.name}</span>{" "}
                  <span className="chat-sender-time">
                    {formatMessageTime(msg?.time)}
                  </span>
                </span>
                <p> {msg.message}</p>
              </div>
            </div>
          )
        )}
        {/* To have the scroll to the bottom */}
      </div>
      <div className="chat-input-container">
        <textarea
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="chat-input"
          placeholder="Enter your message"
        />
        <span
          className="chat-submit-btn"
          onClick={() => {
            message && publishMessage();
          }}
        >
          <IoSend />
        </span>
      </div>
    </div>
  );
}

export default ChatComponent;
