import React from "react";
import "./../styles/InitailLoading.css";

function InitalLoading() {
  return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
      <h1>Connecting...</h1>
      <h1 className="landing-header">Welcome to Incognito Chat</h1>
      <p className="landing-description">Where your identity stay private</p>
    </div>
  );
}
export default InitalLoading;
