import { StompSessionProvider } from "react-stomp-hooks";
import "./App.css";
import ChatComponent from "./components/ChatComponent";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import InitalLoading from "./components/InitalLoading";

function App() {
  const users = useSelector((state) => state.users.users);
  const user = localStorage.getItem("chatapp-user");
  const [menu, setMenu] = useState(false);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMenu(false);
      } else {
        setMenu(true);
      }
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("resize", handleResize);
    handleResize();

    setTimeout(() => {
      setLoading(false); 
    }, 2000); 

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const renderChatRoute = () => {
    if (users.includes(user)) {
      return <ChatComponent />;
    } else {
      localStorage.removeItem("chatapp-user");
      return <Navigate to="/login" />;
    }
  };


  if (loading) {
    return <InitalLoading/>;
  }

  return (
    <div className="chat-app">
      <StompSessionProvider url={`${process.env.REACT_APP_BACKEND_URL}/server`}>
        <BrowserRouter>
          <div className="chatapp-container">
            {menu && (
              <div className="sidebar-container">
                <Sidebar menu={menu} setMenu={setMenu} />
              </div>
            )}
            <div
              className={
                menu ? "chatapp-body-container" : "chatapp-body-container-full"
              }
            >
              <Navbar menu={menu} setMenu={setMenu} />
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/chat" element={renderChatRoute()} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </StompSessionProvider>
    </div>
  );
}

export default App;
