import React from "react";
import "./../styles/Sidebar.css";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { MdCancel } from "react-icons/md";

function Sidebar({ menu, setMenu }) {
  const users = useSelector((state) => state.users.users);

  return (
    <div className="sidebar-container">
      <div className="sidebar-header">
        <div className="sidebar-header-title">
          <h2 className="text-center">Active people</h2>
          <badge className="sidebar-badge">{users.length}</badge>
        </div>
        <span onClick={() => setMenu(!menu)}>
          <MdCancel />
        </span>
      </div>

      <div className="sidebar-user-details">
        {users.length > 0 &&
          users.map((user, index) => {
            return (
              <div className="sidebar-user" key={index}>
                {" "}
                <FaUser /> {user}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Sidebar;
