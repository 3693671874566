export async function fetchUsers() {
  try {
    const url = process.env.REACT_APP_BACKEND_URL + "/users";
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch users");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch users:", error);
  }
}

export function formatMessageTime(timeString) {
  if (!timeString) return ""; // Handle null or undefined time
  const date = new Date(timeString);
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  return date.toLocaleString("en-US", options);
}
